@import url("colours.css");

/* super teeney */
@media (max-width: 340px) {
  .t {
    min-width: 80px !important;
  }
  .widthCC {
    width: 100% !important;
  }
  .widthCC {
    width: 100% !important;
  }

  .comparisonCellLeft {
    padding: 10px;
    font-size: 0.7rem !important;
    text-align: left;
    font-family: "Lufga-Regular";
    height: 60px;
    /* width: 60px; */
  }
  .prExtraSmall {
    margin-left: 20px !important;
  }
  .featureListFont {
    font-size: 10px !important;
    font-family: "Lufga-Medium" !important;
  }
  .legalFooter {
    font-family: Roboto, Arial;
    font-size: 10px  !important;
  }
}

@media (max-width: 400px) {
  .wrapper {
    margin-left: -90px !important;
    /* margin-right: -20px; */
    background-color: #fff;
    padding-left: 20px;
  }
  .ohm {
    overflow: hidden;
  }
  .featureListFont {
    font-size: 10px !important;
    font-family: "Lufga-Medium" !important;
  }
  .legalFooter {
    font-family: Roboto, Arial;
    font-size: 10px  !important;
  }
  .whyJonnyChoseVeloImage {
    position: relative;
    margin-left: -241px;
    margin-top: -250px;
    margin-bottom: -250px;
    transform: scale(0.4);
  }

  .jonnyMobile {
    margin-top: -151px;
    margin-left: -118px;
    overflow: hidden;
    height: 791px;
  }

  .mobileMarginTop {
    margin-top: 10px;
  }

  .mobilePadding {
    padding: 10px;
  }

  /* footer*/
  .footerOverFlow {
    overflow: hidden;
  }

  .ctaButtonImageMargin {
    margin-top: -20%;
  }

  .iconBlockBlue,
  .iconBlockGreen {
    margin-left: 0px;
  }

  .footerRidingBlueBikeSVG {
    left: 80%;
    transform: scale(1.4) !important;
    right: 0px !important;
    bottom: 5px !important;
  }

  .mobilePadding {
    padding-left: 15px;
    padding-right: 15px;
  }

  .protect {
    font-size: 1.6rem !important;
  }

  #socials ul li {
    float: right;
    margin-right: 10px;
  }

  .footerRidingBlueBikeSVG_multi {
    right: 0px !important;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    left: 72%;
  }

  .footerRidingBlueBikeSVG_ebike {
    left: 80%;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    right: 0px !important;
  }

  .footerRidingBlueBikeSVG_mustard {
    left: 80%;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    right: 0px !important;
  }

  /* .CTAButton {
    font-size: small;
    padding: 4px 12px !important;
  } */

  .DesAnDev .CTAButton {
    margin-top: -250px !important;
  }

  .breadcrumb-text {
    font-size: xx-large;
  }

  .mobileWidth {
    width: 70%;
  }

  /* homepage */
  .HeroSingle {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HomePageHero800w.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 430px;
  }

  .HeroMulti {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/MultiHero1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroElectric {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/EbikeHero1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroTravel {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroTravel1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroJonny {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroJonny1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  /* Travel Page */
  .bikeBoxImageContainer {
    display: none !important;
  }

  .bikeBoxImageContainerMobile {
    width: 100%;
    height: 300px;
  }

  .bikeBoxImageContainerMobile img {
    transform: scale(0.7);
    width: 100%;
  }

  .mustardBike {
    transform: scale(1);
    width: 100%;
    z-index: -10;
  }

  .mountainRoadImageTweaks {
    bottom: -950px !important;
  }

  .responsiveAlign {
    text-align: center;
  }

  .headerBlockCopy {
    color: var(--velo-white);
    position: relative;
    z-index: 999;
    width: 96%;
    float: left;
  }

  .whyChooseSingleCoverImage {
    position: absolute;
    bottom: -400px;
    right: -400px;
    transform: scale(0.4);
  }

  .mobile-bike-container {
    position: relative;
    height: 220px;
    overflow: hidden;
    margin-left: -15px;
  }

  .introBlocKBike {
    bottom: -186px;
    left: -266px;
    position: absolute;
    transform: scale(0.6);
  }

  .affiliateMountainbikeMobile {
    margin-left: -360px;
    bottom: 0px;
  }

  .bikeBoxTweaks {
    position: absolute;
    transform: scale(0.3);
    bottom: -295px;
    z-index: 99;
    right: -380px;
  }

  #right {
    padding-top: 0;
  }

  .singleBikeImageContainer {
    padding: 0;
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
    overflow-y: hidden;
  }

  .leisureBikeSingle {
    transform: scale(0.5);
    position: absolute;
    left: -518px;
    top: 30px;
  }

  .StandardFeaturesBike {
    margin-left: -100px;
    margin-top: 50px;
    transform: scale(1.4);
  }

  .EbikeFeaturesBike {
    position: relative;
    margin-left: 150px;
    transform: scale(1.6);
    margin-top: 50px;
    margin-bottom: 20px;
  }

  /* multibike page */
  .urbanBikeTweaks {
    right: -100px;
    bottom: -15px;
    width: 100%;
    transform: scale(0.8);
  }

  .whiteBikeTweaks {
    right: -100px;
    bottom: -15px;
    width: 100%;
    transform: scale(0.8);
  }

  .mountImageTweaks {
    position: absolute;
    left: -50px;
    width: 200%;
  }

  /* ebike cover page */
  .eBikeTweaks {
    position: absolute;
    right: -600px;
    bottom: -170px;
    transform: scale(0.6);
  }

  .peakImageTweaks {
    position: absolute;
    left: -700px;
    bottom: -340px;
    z-index: 0;
  }

  .ebikeBenefits {
    width: 140%;
    margin-left: -120px;
    transform: scale(1.5, 1.5);
  }

  .ebikeImgContainer {
    height: 350px;
  }

  .whyChooseveloEbikeImg {
    position: absolute;
    right: -375px;
    bottom: -425px;
    transform: scale(0.4);
  }

  /* policy information page */
  .helmetImage {
    position: relative;
    margin-left: -250px;
    margin-top: -63px;
    transform: scale(0.6);
  }

  .policyImage {
    transform: scale(0.7);
    width: 100%;
    margin-top: -40px;
    margin-bottom: -52px;
  }

  .paperImage {
    position: relative;
    margin-top: -100px;
    margin-left: -200px;
    transform: scale(0.5);
    margin-bottom: -100px;
  }

  /* security page */
  .securityMacMobile {
    position: absolute;
    transform: scale(0.45);
    top: -390px;
    left: -365px;
  }

  .securitySoldSecure {
    position: absolute;
    max-height: 300px;
    padding: 0;
    top: -200px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }

  .bikeStolenImage {
    transform: scale(0.8) !important;
    margin-left: -300px !important;
    margin-top: -60px !important;
  }

  .bikeLockMargin {
    margin-top: -40px;
  }

  .purchaseALock {
    min-height: 400px;
  }

  /* how it started */
  .signature {
    left: -135px !important;
    bottom: 96px !important;
    transform: scale(0.35) !important;
  }

  .blueBlock p {
    font-size: 27px;
  }

  .blueBlock {
    height: 530px !important;
  }

  .ken-avatar {
    right: 0px !important;
    bottom: 100px;
    transform: scale(0.8);
  }

  .title {
    margin-top: 80px !important;
    line-height: 0;
  }

  /* faq page */
  .mobileRowRow {
    width: 95%;
    margin-left: 0;
    margin-right: 0;
  }

  .FAQ {
    font-size: 1rem !important;
  }

  .filterBlogButton {
    min-width: 30px !important;
    width: 100% !important;
    margin-right: -10% !important;
    margin-bottom: 5px;
    font-size: 13px;
  }

  .dumbfoundedImage {
    position: absolute;
    left: -80px;
    bottom: -150px;
    transform: scale(0.55);
  }

  .giftCard {
    padding-top: 40px;
    margin-left: -75px !important;
    width: 80% !important;
  }

  .cantSeeAnswer {
    height: 220px;
  }

  .faq-header-white > h4 {
    font-size: 1.2rem;
  }

  /* cover features */
  .bikerCoupleImage {
    position: absolute;
    right: -320px;
    bottom: -110px;
    transform: scale(0.5);
  }

  .coverFeatLeft {
    height: 300px;
  }

  /* banner stuff */
  .bannerSVG {
    width: 100% !important;
    transform: translate(59px, -420px) scale(0.3);
  }

  .sharedHeaderBlockCopy p {
    color: white;
    line-height: 1.1em;
    max-width: 400px;
  }

  .sharedHeaderBlockCopy {
    padding-top: 20px;
  }

  #journeyHeadingBlock {
    width: 100%;
    background-color: var(--velo-blue);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    height: 290px;
    padding-top: 45px;
  }

  /* types of bikes */
  .typesOfBikeImgLEFT {
    width: 100%;
    transform: scale(0.7);
  }

  .typesOfBikeImgRIGHT {
    width: 100%;
    transform: scale(0.7);
  }

  .customerLoyalityBlock {
    min-height: 600px;
  }

  /* affiliate page */
  .loyalty {
    transform: scale(0.6);
    position: absolute;
    left: -200px;
    bottom: -430px;
  }

  /* step 3 */
  .monthly-quote-card {
    border: 1px solid #ccc;
    border-top-right-radius: 2.5rem !important;
    border-top-left-radius: 2.5rem !important;
  }

  .annual-quote-card {
    border: 1px solid #ccc;
    border-top-right-radius: 2.5rem !important;
    border-top-left-radius: 2.5rem !important;
  }

  /* journey - quote */
  .QuoteValueLarge {
    font-size: 50px !important;
  }

  .btn-icon-security > svg {
    transform: scale(1.4);
    position: absolute;
    top: 20px;
    left: 20px;
  }

  label > .SecurityLocationButtonLabel {
    position: absolute;
    top: 35px;
    left: 140px;
  }

  /* Affiliate  */
  .AffiliateIntro1 {
    min-height: 450px;
  }

  .AffiliateIntro2 {
    min-height: 580px !important;
  }

  .AffilateScreens {
    transform: scale(0.4);
    margin-top: -227px;
    margin-left: -312px;
  }

  .toolsImage {
    position: absolute;
    top: 35px !important;
    width: 110%;
  }
  .AffiliateIntro1 {
    height: 500px !important;
  }
  .AffiliateIntro2 {
    height: 550px !important;
  }

  .bannerLaptopImage {
    width: 100%;
  }

  .KeyBensOrangeBike {
    position: absolute;
    bottom: -100px;
    right: -100px;
    z-index: -10;
  }

  /* Refer a friend */
  .bikeCoupleRefer {
    margin-top: 1px;
    width: 100%;
    /* transform: scale(1.4); */
    margin-left: 10px;
  }

  /* about us */
  .bikeCoupleAboutUs {
    position: relative;
    transform: scale(0.7);
    bottom: 117px;
    left: 10%;
    margin-bottom: -185px;
  }

  .jonnyTweaks {
    position: absolute;
    left: 50px;
    top: 0px;
  }

  .spacerForNoCTAScenario {
    height: 13px !important;
  }
  .spacerForNoCTAScenarioExtra {
    height: 50px !important;
  }

  .comparisonCell svg {
    /* width: 60% !important;
    height: 60% !important; */
  }
  .comparisonCellLeft {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 0.7rem !important;
    text-align: left;
    height: 100px;
  }

  .comparisonCell {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.4rem;
    text-align: center;
    height: 75px;
    width: 172px;
  }

  .t {
    display: flex;
    font-family: "Lufga-Regular";
    font-size: 15px;
    height: 80px;
    align-items: center;
  }

  .fullWidthBarBlue::after {
    left: 15% !important;
  }
  .fullWidthBarOrange::after {
    left: 74% !important;
  }

  .CorePerformanceButton svg {
    display: none;
  }
  .innerCorePerformanceButton {
    margin-left: -45px !important;
    text-align: center !important;
  }

  .bikerImage {
    position: relative !important;
    left: -50px !important;
    top: -75px !important;
    width: 120% !important;
    transform: scale(0.9) !important;
  }

  .wrapper {
    margin-left: 0px !important;
  }
  .footWrapper {
    margin-left: 0px !important;
  }
  .bikeFall {
    width: 100%;
  }
  /* claims */
  .claim-roadbike {
    margin-top: -110px;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    margin-top: -10px !important;
  }
  .Lrqa {
    width: 20%;
  }
  .singleBikeImageMobileOnly,
  .ebikeImageMobileOnly {
    background-size: 130%;
    background-position-x: -100px;
    background-position-y: -20px;
    background-repeat: no-repeat;
    height: 200px;
    overflow-y: hidden;
    margin-left: -66px;
  }
  .singleBikeImageMobileOnly {
    background-image: url(../Images/leisureBike-w1200.png);
  }
  .ebikeImageMobileOnly {
    background-image: url(../Images/e-bike-black2.png);
  }
  .CTAHeroSmall {
    width: 190px;
    margin-bottom: 20px;
    width: 190px;
    margin-bottom: 20px;
  }
  .coreBikeGirlMobile {
    transform: scale(0.7);
    margin-top: -70px;
    margin-bottom: -40px;
    margin-left: -50px;
  }
  .coreBikeGirlMobileBlue {
    transform: scale(0.8);
    margin-top: -55px;
    margin-bottom: -60px;
    margin-left: -10px;
  }
  .coreBikeManMobile {
    margin-left: -40px;
    width: 120%;
  }
  .smallPrint1000,
  .smallPrint250 {
    margin-top: -12px;
    font-size: 10px !important;
  }
  .CorePerformanceIntroFontSize {
    font-size: 25px;
  }
  .prExtraSmall {
    margin-left: 20px !important;
  }
}
@media (max-width: 575.98px) {
  .offerCog {
    z-index: 9999;
  }
}
/* XS only ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++*/
@media (min-width: 400px) and (max-width: 575.98px) {
  .wrapper {
    margin-left: -90px !important;

    background-color: #fff;
    padding-left: 20px;
  }
  .ohm {
    overflow: hidden;
  }
  .featureListFont {
    font-size: 10px !important;
    font-family: "Lufga-Medium" !important;
  }
  .legalFooter {
    font-family: Roboto, Arial;
    font-size: 10px  !important;
  }
  .carousel-control-next-icon,
  .carousel-control-prev-icon {
    margin-top: -10px !important;
  }

  .bikerImage {
    position: relative !important;
    top: -100px !important;
    left: -50px !important;
    width: 120% !important;
    transform: scale(0.9) !important;
  }

  .mobileMarginTop {
    margin-top: 10px;
  }

  .ebikeBenefits {
    width: 140%;
    margin-left: -120px;
    transform: scale(1.5, 1.5);
  }

  .mobilePadding {
    padding: 10px;
  }

  .iconBlockBlue,
  .iconBlockGreen {
    margin-left: 0px;
  }

  /* footer*/
  .footerOverFlow {
    overflow: hidden;
  }

  .jonnyMobile {
    margin-top: -151px;
    margin-left: -28px;
    overflow: hidden;
    height: 781px;
  }

  .whyJonnyChoseVeloImage {
    position: relative;
    margin-left: -150px;
    margin-top: -200px;
    margin-bottom: -250px;
    transform: scale(0.6);
  }

  .ctaButtonImageMargin {
    margin-top: -20%;
  }

  .footerRidingBlueBikeSVG {
    left: 80%;
    transform: scale(1.4) !important;
    right: 0px !important;
    bottom: 5px !important;
  }

  .mobilePadding {
    padding-left: 15px;
    padding-right: 15px;
  }

  .protect {
    font-size: 1.6rem !important;
  }

  #socials ul li {
    float: right;
    margin-right: 14px;
  }

  .footerRidingBlueBikeSVG_multi {
    right: 0px !important;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    left: 72%;
  }

  .footerRidingBlueBikeSVG_ebike {
    left: 80%;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    right: 0px !important;
  }

  .footerRidingBlueBikeSVG_mustard {
    left: 80%;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    right: 0px !important;
  }

  /* .CTAButton {
    font-size: small;
    padding: 4px 12px !important;
  } */

  .DesAnDev .CTAButton {
    margin-top: -250px !important;
  }

  .breadcrumb-text {
    font-size: xx-large;
  }

  .mobileWidth {
    width: 70%;
  }

  /* homepage */
  .HeroSingle {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HomePageHero800w.jpg);
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    min-height: 430px;
  }

  .HeroMulti {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/MultiHero1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroElectric {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/EbikeHero1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroTravel {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroTravel1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroJonny {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroJonny1000.jpg);
    background-repeat: no-repeat;
    min-height: 350px;
    background-size: 100%;
    background-position: bottom;
  }

  /* Travel Page */
  .bikeBoxImageContainer {
    display: none !important;
  }

  .bikeBoxImageContainerMobile {
    width: 100%;
    height: 300px;
  }

  .bikeBoxImageContainerMobile img {
    transform: scale(0.7);
    width: 100%;
  }

  .mustardBike {
    transform: scale(1);
    width: 100%;
    z-index: -10;
  }

  .mountainRoadImageTweaks {
    bottom: -950px !important;
  }

  .responsiveAlign {
    text-align: center;
  }

  .headerBlockCopy {
    color: var(--velo-white);
    position: relative;
    z-index: 999;
    width: 96%;
    float: left;
  }

  .whyChooseSingleCoverImage {
    position: absolute;
    bottom: -400px;
    right: -400px;
    transform: scale(0.4);
  }

  .mobile-bike-container {
    position: relative;
    height: 220px;
    overflow: hidden;
    margin-left: -15px;
  }

  .introBlocKBike {
    bottom: -186px;
    left: -266px;
    position: absolute;
    transform: scale(0.6);
  }

  .affiliateMountainbikeMobile {
    margin-left: -360px;
    bottom: 0px;
  }

  .bikeBoxTweaks {
    position: absolute;
    transform: scale(0.3);
    bottom: -295px;
    z-index: 99;
    right: -380px;
  }

  #right {
    padding-top: 0;
  }

  .singleBikeImageContainer {
    padding: 0;
    width: 100%;
    height: 300px;
    margin-bottom: 15px;
    display: none;
  }

  .leisureBikeSingle {
    transform: scale(0.5);
    position: absolute;
    left: -391px;
    top: 0;
  }

  .StandardFeaturesBike {
    margin-left: -100px;
    margin-top: 50px;
    transform: scale(1.4);
  }

  .EbikeFeaturesBike {
    position: relative;
    margin-left: 150px;
    transform: scale(1.6);
    margin-top: 50px;
    margin-bottom: 20px;
  }

  /* multibike page */
  .urbanBikeTweaks {
    right: -100px;
    bottom: -15px;
    width: 100%;
    transform: scale(0.8);
  }

  .whiteBikeTweaks {
    right: -100px;
    bottom: -15px;
    width: 100%;
    transform: scale(0.8);
  }

  .mountImageTweaks {
    position: absolute;
    left: -50px;
    width: 200%;
  }

  /* ebike cover page */
  .eBikeTweaks {
    position: absolute;
    right: -600px;
    bottom: -170px;
    transform: scale(0.6);
  }

  .peakImageTweaks {
    position: absolute;
    left: -700px;
    bottom: -340px;
    z-index: 0;
  }

  .ebikeBenefits {
    width: 140%;
    margin-left: -120px;
    transform: scale(-1.5 1.5);
  }

  .ebikeImgContainer {
    height: 350px;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 200%;

    margin-bottom: 15px;
    padding-top: 40px;
  }

  .whyChooseveloEbikeImg {
    position: absolute;
    right: -375px;
    bottom: -425px;
    transform: scale(0.4);
  }

  /* policy information page */
  .helmetImage {
    position: relative;
    margin-left: -250px;
    margin-top: -63px;
    transform: scale(0.6);
  }

  .policyImage {
    transform: scale(0.7);
    width: 100%;
    margin-top: -40px;
    margin-bottom: -65px;
  }

  .paperImage {
    position: relative;
    margin-top: -100px;
    margin-left: -260px;
    transform: scale(0.5);
    margin-bottom: -100px;
  }

  /* security page */
  .securityMacMobile {
    position: absolute;
    transform: scale(0.45);
    top: -450px;
    left: -293px;
  }

  .securitySoldSecure {
    position: absolute;
    max-height: 350px;
    padding: 0;
    top: -250px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }

  .bikeStolenImage {
    transform: scale(0.8) !important;
    margin-left: -200px !important;
    margin-top: -60px !important;
  }

  .bikeLockMargin {
    margin-top: -40px;
  }

  .purchaseALock {
    min-height: 400px;
  }

  /* how it started */
  .signature {
    left: -135px !important;
    bottom: 96px !important;
    transform: scale(0.35) !important;
  }

  .blueBlock p {
    font-size: 27px;
  }

  .blueBlock {
    height: 530px !important;
  }

  .ken-avatar {
    right: 0px !important;
    bottom: 100px;
    transform: scale(0.8);
  }

  .title {
    margin-top: 80px !important;
    line-height: 0;
  }

  /* faq page */
  .mobileRowRow {
    width: 95%;
    margin-left: 0;
    margin-right: 0;
  }

  .FAQ {
    font-size: 1rem !important;
  }

  .filterBlogButton {
    min-width: 30px !important;
    width: 100% !important;
    margin-right: -10% !important;
    margin-bottom: 5px;
    font-size: 13px;
  }

  .dumbfoundedImage {
    position: absolute;
    left: -40px;
    bottom: -100px;
    transform: scale(0.6);
  }

  .giftCard {
    padding-top: 40px;
    margin-left: -75px !important;
    width: 80% !important;
  }

  .cantSeeAnswer {
    height: 220px;
  }

  .faq-header-white > h4 {
    font-size: 1.2rem;
  }

  /* cover features */
  .bikerCoupleImage {
    position: absolute;
    right: -320px;
    bottom: -110px;
    transform: scale(0.5);
  }

  .coverFeatLeft {
    height: 300px;
  }

  /* banner stuff */
  .bannerSVG {
    width: 100% !important;
    transform: translate(153px, -400px) scale(0.45);
  }

  .sharedHeaderBlockCopy p {
    color: white;
    line-height: 1.1em;
    max-width: 400px;
  }

  .sharedHeaderBlockCopy {
    padding-top: 40px;
  }

  #journeyHeadingBlock {
    width: 100%;
    background-color: var(--velo-blue);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    height: 290px;
    padding-top: 45px;
  }

  /* types of bikes */
  .typesOfBikeImgLEFT {
    width: 100%;
    transform: scale(0.7);
  }

  .typesOfBikeImgRIGHT {
    width: 100%;
    transform: scale(0.7);
  }

  .customerLoyalityBlock {
    min-height: 600px;
  }

  /* affiliate page */
  .loyalty {
    transform: scale(0.6);
    position: absolute;
    left: -200px;
    bottom: -430px;
  }

  /* step 3 */
  .monthly-quote-card {
    border: 1px solid #ccc;
    border-top-right-radius: 2.5rem !important;
    border-top-left-radius: 2.5rem !important;
  }

  .annual-quote-card {
    border: 1px solid #ccc;
    border-top-right-radius: 2.5rem !important;
    border-top-left-radius: 2.5rem !important;
  }

  /* journey - quote */
  .QuoteValueLarge {
    font-size: 50px !important;
  }

  .btn-icon-security > svg {
    transform: scale(1.4);
    position: absolute;
    top: 20px;
    left: 20px;
  }

  label > .SecurityLocationButtonLabel {
    position: absolute;
    top: 35px;
    left: 140px;
  }

  /* Affiliate  */
  .AffiliateIntro1 {
    min-height: 450px;
  }

  .AffiliateIntro2 {
    min-height: 570px;
  }

  .AffilateScreens {
    transform: scale(0.47);
    margin-top: -160px;
    margin-left: -300px;
    margin-bottom: -147px;
  }

  .toolsImage {
    position: absolute;
    top: 0;
    width: 120%;
  }
  .AffiliateIntro1 {
    min-height: 300px !important;
  }
  .AffiliateIntro2 {
    height: 550px !important;
  }

  .bannerLaptopImage {
    width: 100%;
  }

  .KeyBensOrangeBike {
    position: absolute;
    bottom: -100px;
    right: -100px;
    z-index: -10;
  }

  /* Refer a friend */
  .bikeCoupleRefer {
    margin-top: 32px;
    width: 70%;
    /* transform: scale(1.4); */
    margin-left: 38px;
  }

  /* about us */
  .bikeCoupleAboutUs {
    position: relative;
    transform: scale(0.7);
    bottom: 117px;
    left: 12%;
    margin-bottom: -185px;
  }

  .filterBlogButton {
    min-width: 145px;
    margin-bottom: 5px;
  }

  .jonnyTweaks {
    position: absolute;
    left: 110px;
    top: 0px;
  }

  .jonnyIntroBlock {
    position: absolute;
    left: -250px;
    bottom: 0px;
  }

  .spacerForNoCTAScenario {
    height: 3px !important;
  }
  .spacerForNoCTAScenarioExtra {
    height: 10px !important;
  }

  .comparisonCellLeft {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 0.8rem !important;
    text-align: left;
    height: 100px;
    width: 92px!;
  }

  .comparisonCell {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.2rem;
    text-align: center;
    height: 65px;
    width: 50px !important;
    top: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .t {
    display: flex;
    font-family: "Lufga-Regular";
    font-size: 15px;
    height: 80px;
    align-items: center;
    width: 100px !important;
  }

  .fullWidthBarBlue::after {
    left: 13% !important;
  }
  .fullWidthBarOrange::after {
    left: 65% !important;
  }

  .wrapper {
    margin-left: 0px !important;
  }
  .footWrapper {
    margin-left: 0px !important;
  }
  .bikeFall {
    width: 100%;
  }
  /* claims */
  .claim-roadbike {
    margin-top: -70px;
  }
  .Lrqa {
    width: 20%;
  }

  .singleBikeImageMobileOnly,
  .ebikeImageMobileOnly {
    background-size: 110%;
    background-position-x: -100px;
    background-position-y: -20px;
    background-repeat: no-repeat;
    height: 250px;
    overflow-y: hidden;
    margin-left: -66px;
  }
  .singleBikeImageMobileOnly {
    background-image: url(../Images/leisureBike-w1200.png);
  }
  .ebikeImageMobileOnly {
    background-image: url(../Images/e-bike-black2.png);
  }
  .CTAHeroSmall {
    width: 190px;
    margin-bottom: 20px;
  }
  .coreBikeGirlMobile {
    transform: scale(0.6);
    margin-top: -80px;
    margin-bottom: -40px;
  }
  .coreBikeGirlMobileBlue {
    transform: scale(0.9);
    margin-top: -25px;
    margin-bottom: -60px;
    margin-left: -10px;
  }
  .coreBikeManMobile {
    margin-left: 0px;
    width: 100%;
  }
  .smallPrint1000,
  .smallPrint250 {
    margin-top: -15px;
  }
  .CorePerformanceIntroFontSize {
    font-size: 25px;
  }
  .prExtraSmall {
    margin-left: 20px !important;
  }
}

/* SM only =================================================================================================================================*/
@media (min-width: 576px) and (max-width: 767.48px) {
  .wrapper {
    margin-left: -30px !important;
    margin-right: -90px;
    background-color: #fff;
    padding-left: 20px;
  }
  .footWrapper {
    margin-left: 0px !important;
  }

  .footerRidingBlueBikeSVG_multi {
    right: 0px !important;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    left: 67%;
  }

  .footerOverFlow {
    overflow: hidden;
  }

  .mobileMarginTop {
    margin-top: 10px;
  }

  .mobilePadding {
    padding: 10px;
  }

  .carousel-img {
    width: 116% !important;
    margin-top: 45px;
  }

  .footerRidingBlueBikeSVG_ebike {
    left: 67%;
    transform: scale(0.8) !important;
    right: 0px !important;
    bottom: -10px !important;
    right: 0px !important;
  }

  .footerRidingBlueBikeSVG {
    left: 70%;
    transform: scale(1.6) !important;
    right: 0px !important;
    bottom: 10px !important;
  }

  .footerRidingBlueBikeSVG_mustard {
    left: 77%;
    transform: scale(0.7) !important;
    right: 0px !important;
    bottom: -15px !important;
    right: 0px !important;
  }

  /* homepage */
  .HeroSingle {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HomePageHero800w.jpg);
    background-repeat: no-repeat;

    min-height: 410px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroMulti {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/MultiHero1000.jpg);
    background-repeat: no-repeat;
    min-height: 410px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroElectric {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/EbikeHero1000.jpg);
    background-repeat: no-repeat;
    min-height: 410px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroTravel {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroTravel1000.jpg);
    background-repeat: no-repeat;
    min-height: 410px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroJonny {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroJonny1000.jpg);
    background-repeat: no-repeat;
    min-height: 410px;
    background-size: 100%;
    background-position: bottom;
  }

  .headerBlockCopy {
    color: var(--velo-white);
    position: relative;
    z-index: 999;
    width: 96%;
    float: left;
  }

  .responsiveAlign {
    text-align: center;
  }

  .DesAnDev .CTAButton {
    margin-top: -250px !important;
  }

  /* Travel Page */
  .bikeBoxTweaks {
    position: absolute;
    transform: scale(0.4);
    bottom: -295px;
    z-index: 99;
    right: -315px;
  }

  /* Travel Page */
  .bikeBoxImageContainer {
    display: none !important;
  }

  .bikeBoxImageContainerMobile {
    width: 90%;
    height: 400px;
  }

  .bikeBoxImageContainerMobile img {
    transform: scale(0.9);
    width: 100%;
  }

  .whyTI {
    min-height: 52vh;
  }

  .mustardBike {
    width: 400%;
    z-index: -10;
    margin-left: -50px;
  }

  #right {
    padding-top: 0 !important;
  }

  /* singleBikeCover page */
  /* single bike cover */
  .whyChooseSingleCoverImage {
    position: absolute;
    bottom: -400px;
    right: -400px;
    transform: scale(0.4);
  }

  .singleBikeImageContainer {
    padding: 0;
    width: 100%;
    height: 320px;
    margin-bottom: 15px;
    margin-top: 15px;
    display: none;
  }

  .leisureBikeSingle {
    transform: scale(0.5);
    position: absolute;
    left: -391px;
    bottom: 40px;
  }

  /* multibike cover page */
  .mountImageTweaks {
    position: absolute;
    left: -200px;
    bottom: -350px;
    z-index: 0;
  }

  .urbanBikeTweaks {
    right: -100px;

    width: 100%;
    transform: scale(0.8);
  }

  .whiteBikeTweaks {
    right: -100px;

    width: 100%;
    transform: scale(0.8);
  }

  /* ebike cover page */
  .eBikeTweaks {
    position: absolute;
    right: -450px;
    bottom: -145px;
    transform: scale(0.7);
  }

  .peakImageTweaks {
    position: absolute;
    left: -700px;
    bottom: -340px;
    z-index: 0;
  }

  .ebikeBenefits {
    width: 140%;
    margin-left: -120px;
    transform: scale(1.5, 1.5);
  }

  .ebikeImgContainer {
    height: 350px;
    overflow-y: hidden;
    overflow-x: hidden;
    width: 200%;
    margin-left: -100px;
    margin-bottom: 15px;
    padding-top: 40px;
  }

  .whyChooseveloEbikeImg {
    position: absolute;
    bottom: -200px;
    left: 0px;
    transform: scale(0.6);
  }

  .mobile-bike-container {
    /* position: relative;
    height: 300px;
    overflow-y: hidden;
    width: 1000px; */
    position: relative;
    height: 385px;
    overflow-y: hidden;
    width: 1000px;
    margin-left: -304px;
    margin-top: -70px;
    margin-bottom: -20px;
    transform: scale(0.6);
    /* border:1px solid red; */
  }

  .introBlocKBike {
    position: absolute;
    bottom: -100px;
    left: -100px;
  }

  /* policy information page */
  .helmetImage {
    position: absolute;
    bottom: 50px;
    right: -420px;
    transform: scale(0.35);
  }

  .paperImage {
    position: absolute;
    top: -20px;
    left: -260px;
    transform: scale(0.4);
  }

  /* travel */

  /* security page */
  .securityMac {
    position: absolute;
    transform: scale(0.4);
    top: -180px;
    right: -346px;
  }

  .securitySoldSecure {
    position: absolute;
    max-height: 350px;
    padding: 0;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }

  .bestLockImage {
    transform: scale(0.5);
    margin-left: -150px;
  }

  /* faq page */
  .dumbfoundedImage {
    position: absolute;
    left: 100px;
    top: 30px;
    transform: scale(1.1);
  }

  .faq-header-white > h4 {
    font-size: 1.2rem;
  }

  /* cover features */
  .bikerCoupleImage {
    position: absolute;
    right: -220px;
    bottom: -90px;
    transform: scale(0.6);
  }

  .coverFeatLeft {
    height: 300px;
  }

  .StandardFeaturesBike {
    margin-left: -100px;
    margin-top: 50px;
    margin-bottom: 30px;
    transform: scale(1.4);
  }

  .EbikeFeaturesBike {
    margin-left: 150px;
    transform: scale(1.6);
    margin-top: 60px;
    margin-bottom: 30px;
  }

  /* banner stuff */
  .bannerSVG {
    width: 100%;
    transform: translate(217px, -420px) scale(0.45);
  }

  .sharedHeaderBlockCopy p {
    color: white;
    line-height: 1.1em;
    max-width: 400px;
  }

  .sharedHeaderBlockCopy {
    padding-top: 20px;
  }

  #journeyHeadingBlock {
    position: relative;
    width: 100%;
    background-color: var(--velo-blue);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    height: 250px;
    padding-top: 45px;
  }

  .signature {
    left: -135px !important;
    bottom: 126px !important;
    transform: scale(0.35) !important;
  }

  .policyImage {
    transform: scale(0.6);
    width: 100%;
  }

  .forgottenPolicyContainer {
    min-height: 10vh !important;
  }

  /* types of bikes */
  .typesOfBikeImgLEFT {
    position: absolute;
    left: -375px;
    transform: scale(0.7);
  }

  .typesOfBikeImgRIGHT {
    position: absolute;
    right: -375px;
    transform: scale(0.7);
  }

  /* journey - quote */
  .QuoteValueLarge {
    font-size: 50px !important;
  }

  .btn-icon-security > svg {
    transform: scale(1.4);
    position: absolute;
    top: 20px;
    left: 20px;
  }

  label > .SecurityLocationButtonLabel {
    position: absolute;
    top: 35px;
    left: 140px;
  }

  /* how it started */
  .signature {
    left: -135px !important;
    bottom: 146px !important;
    transform: scale(0.35) !important;
  }

  .blueBlock p {
    font-size: 27px;
  }

  .blueBlock {
    height: 500px !important;
  }

  .title {
    margin-top: 120px !important;
    line-height: 0;
  }

  /* affiliate page */
  .loyalty {
    position: absolute;
    right: -900px;
    bottom: -190px;
    transform: scale(0.5);
  }

  .AffilateScreens {
    width: 120%;
    transform: scale(1);
  }

  .toolsImage {
    width: 100%;
  }

  .bannerLaptopImage {
    width: 100%;
  }

  .KeyBensOrangeBike {
    position: absolute;
    bottom: -50px;
    right: -200px;
    z-index: -10;
  }

  .filterBlogButton {
    min-width: 105px;
    width: 100%;
    margin-bottom: 5px;
  }

  .jonnyTweaks {
    position: absolute;
    left: 190px;
    top: 0px;
  }

  .jonnyIntroBlock {
    position: absolute;
    left: -250px;
    bottom: 0px;
  }

  .whyJonnyChoseVeloImage {
    position: absolute;
    left: 350px;
    bottom: -150px;
  }

  .giftCard {
    padding-top: 70px;
    margin-left: -70px;
    width: 100%;
    transform: scale(1.5);
  }

  /* Refer a friend */
  .bikeCoupleRefer {
    margin-top: 232px;
    width: 100%;
    /* transform: scale(1.4); */
    margin-left: 30px;
  }

  .spacerForNoCTAScenario {
    height: 23px !important;
  }
  .spacerForNoCTAScenarioExtra {
    height: 50px !important;
  }

  .comparisonCellLeft {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 100px;
    /* width: 172px; */
  }
  .t {
    display: flex;
    /* width: 60px !important; */
    font-family: "Lufga-Regular";
    font-size: 15px;
    /* border-bottom: 0.5px solid #ccc; */
    height: 80px;
    align-items: center;
  }
  .comparisonCell {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 65px;
    width: 172px;
    top: 100px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .fullWidthBarBlue::after {
    left: 16% !important;
  }
  .fullWidthBarOrange::after {
    left: 80% !important;
  }
  .bikerImage {
    position: relative !important;
    bottom: 100px !important;
    left: -50px !important;
    width: 120% !important;
    transform: scale(0.9) !important;
  }
  .card-headerMob {
    border-top-left-radius: 2.4rem !important;
    border-top-right-radius: 2.4rem !important;
    /* margin-top: -4px; */
    width: 100%;
  }
  .monthly-quote-card {
    border: 1px solid #ccc;
    border-radius: 2.5rem !important;
  }
  .bikeFall {
    width: 100%;
  }
  .Lrqa {
    width: 75%;
    margin-top: -50px;
    margin-top: -50px;
  }
  .singleBikeImageMobileOnly,
  .ebikeImageMobileOnly {
    background-size: 140%;
    background-position-x: -214px;
    background-position-y: -20px;
    background-repeat: no-repeat;
    height: 390px;
    overflow-y: hidden;
    margin-left: -125px;
  }
  .singleBikeImageMobileOnly {
    background-image: url(../Images/leisureBike-w1200.png);
  }
  .ebikeImageMobileOnly {
    background-image: url(../Images/e-bike-black2.png);
  }

  .coreBikeGirlMobile {
    transform: scale(0.7);
    margin-top: -80px;
    margin-bottom: -40px;
  }
  .coreBikeGirlMobileBlue {
    transform: scale(0.7);
    margin-top: -80px;
    margin-bottom: -40px;
  }
  .coreOverrideTitle {
    width: 200px;
    height: 92px !important;
    font-family: "Lufga-Regular" !important;
    font-size: 22px !important;
  }
  .coreOverrideCol {
    width: 200px;
    height: 92px !important;
    font-family: "Lufga-Medium" !important;
    font-size: 30px !important;
  }

  /* .coreOverrideCol:nth-child(1) {
    margin-left:15px;
  } */
  /* .paddingBetweenCols > tbody > tr:first-child .comparisonCell,
  .paddingBetweenCols > tbody > tr:first-child .titleCell {
    height: 102px !important;
  } */
  .comparisonCell p {
    margin-top: -6px;
    font-family: "Lufga-Light";
    font-size: 13px;
    color: #bbb;
  }
  .coreSmallprint {
    padding-left: 70px;
    padding-right: 70px;
  }

  .CTAHeroSmall {
    width: 190px;
  }
  .CorePerformanceIntroFontSize {
    font-size: 25px;
  }
}

/* MD only ==================================================================================================================================*/
@media (min-width: 768px) and (max-width: 991.98px) {
  .md-mt-5 {
    margin-top: 50px;
  }
  .thanksImage {
    margin-left: -220px !important;
    margin-top: -160px !important;
    scale: 0.6 !important;
  }
  .contactUsSuccess {
    height: 100px !important;
  }
  .CTAHeroSmall {
    width: 124px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 250px 160px 160px 160px 160px 160px 160px;
    grid-column-gap: 5px;
    color: #444;
    margin-bottom: 15px;
    margin-left: 0px !important;
  }
  .footWrapper {
    margin-left: 0px !important;
  }
  .card-headerMob {
    border-top-left-radius: 2.4rem;
    border-top-right-radius: 2.4rem;
    margin-top: -4px;
    width: 100%;
  }

  .footerOverFlow {
    margin: 0%;
  }

  /* homepage */
  .roadBikeTweaks {
    position: absolute;
    right: -200px;
    bottom: -80px;
    transform: scale(0.9);
  }

  .mobileMarginTop {
    margin-top: 10px;
  }

  .mobilePadding {
    padding-left: 20px;
  }

  .carousel-img {
    width: 116% !important;
    margin-top: 45px;
  }

  .HeroSingle {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HomePageHero1000w.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    min-height: 550px;
  }

  .HeroMulti {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/MultiHero1400.jpg);
    background-repeat: no-repeat;
    min-height: 550px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroElectric {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/EbikeHero1400.jpg);
    background-repeat: no-repeat;
    min-height: 550px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroTravel {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroTravel1400.jpg);
    background-repeat: no-repeat;
    min-height: 550px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroJonny {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroJonny1400.jpg);
    background-repeat: no-repeat;
    min-height: 550px;
    background-size: 100%;
    background-position: bottom;
  }

  .headerBlockCopy {
    color: var(--velo-white);
    position: relative;
    z-index: 999;
    width: 96%;
    float: left;
  }

  .introBlocKBike {
    position: absolute;
    bottom: -150px;
    left: -400px;
  }

  .responsiveAlign {
    text-align: left;
    margin-left: 20px;
  }

  /* Travel Page */
  .bikeBoxTweaks {
    position: absolute;
    transform: scale(0.4);
    bottom: -300px;
    right: -400px;
  }

  .bikeBoxImageContainer {
    display: none !important;
  }

  .bikeBoxImageContainerMobile {
    width: 100%;
    height: 464px;
  }

  .bikeBoxImageContainerMobile img {
    transform: scale(0.9);
    width: 80%;
    margin-top: -50px;
    margin-bottom: 50px;
  }

  .whyTI {
    min-height: 60vh;
  }

  .mustardBike {
    width: 400%;
    z-index: -10;
    transform: scale(0.9);
    margin-left: -70px;
  }

  #right {
    padding-top: 0 !important;
  }

  /* singleBikeCover page */
  .singleBikeLeisure {
    position: absolute;
    left: -600px;
    bottom: -270px;
    transform: scale(0.6);
    z-index: -10;
  }

  /* multibike cover page */
  .mountImageTweaks {
    position: absolute;
    left: -200px;
    bottom: -350px;
    z-index: 0;
  }

  /* ebike cover page */
  .eBikeTweaks {
    position: absolute;
    right: -450px;
    bottom: -45px;
  }

  .peakImageTweaks {
    position: absolute;
    left: -200px;
    bottom: -40px;
    z-index: 0;
  }

  .typesOfBikeImgLEFTbigger {
    position: absolute;
    right: -200px;
    bottom: -480px;
    transform: scale(0.5);
    z-index: -10;
  }

  .whyChooseveloEbikeImg {
    position: absolute;
    bottom: -176px;
    left: 120px;
    transform: scale(0.8);
  }

  /* policy information page */
  .helmetImage {
    position: absolute;
    bottom: 50px;
    right: -360px;
    transform: scale(0.55);
  }

  .paperImage {
    position: absolute;
    top: -20px;
    left: -260px;
    transform: scale(0.5);
  }

  /* security page */
  .securityMac {
    position: absolute;
    transform: scale(0.4);
    top: -146px;
    right: -303px;
  }

  .securitySoldSecure {
    position: absolute;
    max-height: 400px;
    padding: 0;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }

  .bestLockImage {
    transform: scale(0.6);
    margin-left: -100px;
  }

  /* faq page */
  .dumbfoundedImage {
    position: absolute;
    right: 250px;
    bottom: 5px;
    transform: scale(1.1);
  }

  /* cover features */
  .bikerCoupleImage {
    position: absolute;
    right: -60px;
    bottom: -70px;
    transform: scale(0.8);
  }

  .coverFeatLeft {
    height: 400px;
  }

  .StandardFeaturesBike {
    position: absolute;
    left: -420px;
    bottom: 150px;
    transform: scale(0.5);
  }

  .EbikeFeaturesBike {
    position: absolute;
    right: -890px;
    bottom: 100px;
    transform: scale(0.4);
  }

  /* banner stuff */
  .bannerSVG {
    width: 100% !important;
    transform: translate(266px, -316px) scale(0.6);
  }

  .sharedHeaderBlockCopy p {
    color: white;
    line-height: 1.1em;
    max-width: 400px;
  }

  .sharedHeaderBlockCopy {
    padding-top: 60px;
  }
  .spacerForNoCTAScenario {
    height: 23px !important;
  }
  .spacerForNoCTAScenarioExtra {
    height: 50px !important;
  }

  #journeyHeadingBlock {
    width: 100%;
    background-color: var(--velo-blue);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    height: 400px;
    padding-top: 45px;
  }

  /* about us */
  .bikeCoupleAboutUs {
    position: relative;
    left: 30%;
  }

  .Ken-name {
    font-size: 21px;
  }

  .ken-avatar {
    transform: scale(1);
  }

  /* how it started */
  .signature {
    left: -135px !important;
    bottom: 106px !important;
    transform: scale(0.35) !important;
  }

  /* refer a friend */
  .whyRefer {
    min-height: 16vh;
  }

  .whyRefer h3 {
    font-size: 27px;
  }

  .policyImage {
    transform: scale(0.6);
    width: 100%;
  }

  /* types of bikes */
  .typesOfBikeImgLEFT {
    position: absolute;
    left: -305px;
    transform: scale(0.8);
  }

  .typesOfBikeImgRIGHT {
    position: absolute;
    right: -305px;
    transform: scale(0.8);
  }

  /* cover comparison */
  .bikerImage {
    position: absolute;
    left: -400px;
    bottom: -250px;
    transform: scale(0.8);
  }

  /* journey - quote */
  .QuoteValueLarge {
    font-size: 50px !important;
  }

  .or {
    position: absolute;
    top: 23%;
    left: 43.3%;
  }

  .btn-icon-security > svg {
    transform: scale(1.4);
    position: absolute;
    top: 20px;
    left: 20px;
  }

  label > .SecurityLocationButtonLabel {
    position: absolute;
    top: 35px;
    left: 140px;
  }

  /* affiliate page */
  .loyalty {
    position: absolute;
    right: -1050px;
    bottom: -170px;
    transform: scale(0.7);
  }

  .toolsImage {
    position: absolute;
    bottom: -50px;
    width: 110%;
  }

  .AffilateScreens {
    transform: scale(0.5);
    margin-top: -109px;
    margin-left: -359px;
    margin-bottom: -168px;
  }

  .bannerLaptopImage {
    width: 100%;
    transform: scale(1.8);
    margin-top: 100px;
  }

  .KeyBensOrangeBike {
    position: absolute;
    bottom: -100px;
    right: -300px;
    z-index: -10;
  }

  /* single bike cover */
  .whyChooseSingleCoverImage {
    position: absolute;
    bottom: -410px;
    right: -500px;
    transform: scale(0.5);
  }

  .filterBlogButton {
    min-width: 105px;
    width: 100%;
    margin-bottom: 5px;
  }

  .jonnyTweaks {
    position: absolute;
    left: 450px;
    top: 0px;
  }

  .jonnyIntroBlock {
    position: absolute;
    left: -200px;
    bottom: 0px;
  }

  .whyJonnyChoseVeloImage {
    position: absolute;
    left: 173px;
    bottom: -230px;
    transform: scale(0.7);
  }

  .EbikeAboutus {
    margin-left: -540px;
    margin-top: -250px;
  }

  .giftCard {
    padding-top: 50px;
    margin-left: -120px;
    width: 100%;
    transform: scale(1.3);
  }

  /* Refer a friend */
  .bikeCoupleRefer {
    margin-top: 88px;
    width: 100%;
    /* transform: scale(1.4); */
    margin-left: 100px;
  }

  .comparisonCell {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 0.9rem;

    border-bottom: 1px solid #ccc;
    height: 80px;
    width: 96px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .smallPrint250,
  .smallPrint1000 {
    margin-bottom: -24px !important;
    font-size: smaller;
  }
  .titleCell {
    display: flex;
    width: 95px;
    font-family: Lufga-Regular;
    font-size: 14px;
    border-bottom: 0.5px solid #ccc;
    height: 80px;
    align-items: center;
    justify-content: center;
  }
  .fullWidthBarBlue::after {
    left: 20% !important;
  }
  .fullWidthBarOrange::after {
    left: 75% !important;
  }

  /* .comparisonCell svg {
    width: 30% !important;
    height: 30% !important;
  } */
  /* .comparisonCell svg {
    width: 100% !important;
    height: 100% !important;
  } */

  .smallPrint1000::after {
    font-size: 10px !important;
  }

  .smallPrint250::after {
    padding: 20;
    font-size: 10px !important;
  }

  .bikeStolenImage {
    transform: scale(1.3);
    margin-left: 10px;
    margin-top: 120px;
  }
  .bikeFall {
    margin-left: -10%;
    width: 115%;
  }
  .Lrqa {
    width: 75%;
    margin-top: -30px;
    margin-top: -30px;
  }
  .footerRidingBlueBikeSVG_mustard {
    left: 77%;
    transform: scale(0.7) !important;
    right: 0px !important;
    bottom: -15px !important;
    right: 0px !important;
  }
  .coreGirlMargin {
    margin-top: -130px;
    margin-bottom: -50px;
  }
  .coreGirlImg {
    position: absolute;
    bottom: -100px;
    right: -425px;
    transform: scale(1.4);
    z-index: -1;
  }
  .coreGirlImgBlue {
    position: relative;
    bottom: -75px;
    transform: scale(0.8);
    right: 130px;
    z-index: -1;
  }
  .coreManImg {
    position: absolute;
    bottom: -100px;
    left: -250px;
    transform: scale(1);
    z-index: -1;
  }
  .coreIconText {
    padding-right: 15%;
  }
  .coreiconSecondRow {
    padding-right: 60% !important;
  }
  .coreExtraPadding {
    padding-right: 70%;
  }
  .coreOverrideTitle {
    width: 250px;
    height: 92px !important;
    font-family: "Lufga-Regular" !important;
    font-size: 22px !important;
  }
  .coreOverrideCol {
    width: 220px;
    height: 92px !important;
    font-family: "Lufga-Medium" !important;
    /* font-size: 30px !important; */
  }
  .CorePerformanceIntroFontSize {
    font-size: 29px;
  }
}

/* LG only ==============================================================================================================LG============================ */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .bikeStolenImage {
    transform: scale(1.3);
    margin-left: 90px;
    margin-top: 50px;
  }
  .CTAHeroSmall {
    width: 124px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }
  .footerRidingBlueBikeSVG {
    left: 95%;
    transform: scale(2) !important;
    right: 0px !important;
    bottom: 15px !important;
  }
  .thanksImage {
    margin-left: -286px !important;
    margin-top: -160px !important;
    scale: 0.6 !important;
  }

  .mobileMarginTop {
    margin-top: 40px !important;
  }

  .mobilePadding {
    padding-left: 20px;
  }

  .footerRidingBlueBikeSVG_multi {
    right: 0px !important;
    transform: scale(1) !important;
    right: 0px !important;
    bottom: -1px !important;
    left: 90%;
  }

  /* homepage */
  .HeroSingle {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HomePageHero1400w.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    min-height: 720px;
  }

  .HeroMulti {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/MultiHero2000.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroElectric {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/EbikeHero2000.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroTravel {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroTravel2000.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroJonny {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroJonny2000.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .headerBlockCopy {
    color: var(--velo-white);
    position: relative;
    z-index: 999;
    width: 66%;
    float: left;
  }

  .introBlocKBike {
    position: absolute;
    bottom: -25px;
    left: -300px;
    /* z-index: -1; */
  }
  #icons a p {
    text-align: center !important;
    color: black;
    font-size: 18px !important;
  }

  .responsiveAlign {
    text-align: left;
  }

  /* Travel Page */
  .bikeBoxTweaks {
    position: absolute;
    transform: scale(0.6);
    bottom: -200px;
    right: -300px;
  }

  .bikeBox {
    position: absolute;
    left: -431px;
    bottom: -488px;
    transform: scale(0.5);
    z-index: -1;
  }

  .whyTI {
    min-height: 90vh;
  }

  .mustardBike {
    position: absolute;
    right: -800px;
    transform: scale(0.4);
    bottom: -380px;
    z-index: -10;
  }

  /* singleBikeCover page */
  .singleBikeLeisure {
    position: absolute;
    left: -540px;
    bottom: -180px;
    transform: scale(0.75);
    z-index: -10;
  }

  /* multibike cover page */
  .mountImageTweaks {
    position: absolute;
    left: 0px;
    bottom: -450px;
    z-index: 0;
  }

  /* ebike cover page */
  .eBikeTweaks {
    position: absolute;
    right: -306px;
    bottom: -45px;
  }

  .peakImageTweaks {
    position: absolute;
    left: -200px;
    bottom: -40px;
    z-index: 0;
  }

  .typesOfBikeImgLEFTbigger {
    position: absolute;
    right: 10%;
    bottom: -450px;
    transform: scale(0.6);
  }

  .whyChooseveloEbikeImg {
    position: absolute;
    right: -271px;
    bottom: -299px;
    transform: scale(0.7);
  }

  /* policy information page */
  .helmetImage {
    position: absolute;
    bottom: 0px;
    right: -360px;
    transform: scale(0.75);
  }

  .paperImage {
    position: absolute;
    top: -20px;
    left: -160px;
    transform: scale(0.6);
  }

  /* security page */
  .securityMac {
    position: absolute;
    transform: scale(0.4);
    top: -203px;
    right: -209px;
  }

  .securitySoldSecure {
    position: absolute;
    max-height: 400px;
    padding: 0;
    top: -50px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }

  .bestLockImage {
    transform: scale(0.5);
    margin-left: -50px;
  }

  /* faq page */
  .dumbfoundedImage {
    position: absolute;
    right: 250px;
    bottom: 5px;
    transform: scale(1.1);
  }

  .faq-header {
    font-size: 16px;
  }

  /* cover features */
  .bikerCoupleImage {
    position: absolute;
    right: -146px;
    bottom: -45px;
    transform: scale(1.1);
  }

  .StandardFeaturesBike {
    position: absolute;
    left: -320px;
    bottom: 150px;
    transform: scale(0.5);
  }

  .EbikeFeaturesBike {
    position: absolute;
    right: -760px;
    bottom: -100px;
    transform: scale(0.45);
  }

  /* banner stuff */
  .bannerSVG {
    width: 100% !important;
    transform: translate(392px, -338px) scale(0.6);
  }

  .sharedHeaderBlockCopy p {
    color: white;
    line-height: 1.1em;
    max-width: 400px;
  }

  .sharedHeaderBlockCopy {
    padding-top: 60px;
  }

  #journeyHeadingBlock {
    width: 100%;
    background-color: var(--velo-blue);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    height: 400px;
    padding-top: 45px;
  }

  /* about us */
  .bikeCoupleAboutUs {
    position: absolute;
    left: 74px;
    bottom: 67px;
    transform: scale(1.3);
  }

  .signature {
    left: -135px !important;
    bottom: 56px !important;
    transform: scale(0.35) !important;
  }

  .ken-avatar {
    right: 0px !important;
    bottom: 20px;
    transform: scale(0.6);
  }

  .Ken-name {
    font-size: 21px;
  }

  /* refer a friend */
  .whyRefer {
    min-height: 25vh;
  }

  .policyImage {
    transform: scale(0.6);
    margin-top: -150px;
    margin-bottom: -100px;
    margin-left: -100px;
  }

  /* types of bikes */
  .typesOfBikeImgLEFT {
    position: absolute;
    left: -299px;
    bottom: -90px;
  }

  .typesOfBikeImgRIGHT {
    position: absolute;
    right: -299px;
    bottom: -90px;
  }

  /* cover comparison */
  .bikerImage {
    position: absolute;
    left: -250px;
    top: -150px;
    transform: scale(0.8);
  }

  /* journey - quote */
  .QuoteValueLarge {
    font-size: 60px !important;
  }

  .or {
    position: absolute;
    top: 23%;
    left: 44.3%;
  }

  .btn-icon-security > svg {
    transform: scale(1.2);
    position: absolute;
    left: 50px;
    top: 10px;
  }

  .btn-icon-security {
    min-height: 55px !important;
  }

  /* how it started */
  .signature {
    left: -115px !important;
    bottom: 126px !important;
    transform: scale(0.45) !important;
  }

  .blueBlock p {
    font-size: 27px;
  }

  .blueBlock {
    height: 500px !important;
  }

  .title {
    margin-top: 100px !important;
  }

  .Ken-name {
    margin-bottom: 20px;
  }

  .ken-avatar {
    transform: scale(0.8);
  }

  /* affiliate page */
  .loyalty {
    position: absolute;
    right: -1150px;
    bottom: -170px;
    transform: scale(0.7);
  }

  .toolsImage {
    position: absolute;
    bottom: -95px;
    width: 136%;
  }

  /* single bike cover */
  .whyChooseSingleCoverImage {
    position: absolute;
    bottom: -420px;
    right: -450px;
    transform: scale(0.5);
  }

  .AffilateScreens {
    transform: scale(0.5);
    margin-top: -108px;
    margin-left: -277px;
    margin-bottom: -91px;
  }

  .bannerLaptopImage {
    width: 100%;
    transform: scale(1.6);
    margin-top: 100px;
    margin-left: -100px;
  }

  .KeyBensOrangeBike {
    position: absolute;
    bottom: -250px;
    right: -500px;
    z-index: -10;
  }

  .filterBlogButton {
    min-width: 135px;
    width: 90%;
    margin-bottom: 5px;
  }

  .jonnyTweaks {
    position: absolute;
    left: 450px;
    top: 0px;
  }

  .jonnyIntroBlock {
    position: absolute;
    left: -200px;
    top: 100px;
  }

  .whyJonnyChoseVeloImage {
    position: absolute;
    left: 428px;
    bottom: -150px;
  }

  .EbikeAboutus {
    margin-left: -500px;
    margin-top: 50px;
  }

  .giftCard {
    padding-top: 40px;
    margin-left: -140px;
    width: 100%;
    bottom: 0;
  }

  /* Refer a friend */
  .bikeCoupleRefer {
    margin-top: 32px;
    width: 80%;
    /* transform: scale(1.4); */
    margin-left: 100px;
  }

  .marginTop {
    margin-top: -100px;
  }

  .spacerForNoCTAScenario {
    height: 23px !important;
  }
  .spacerForNoCTAScenarioExtra {
    height: 50px !important;
  }

  .wrapper {
    margin-left: 0px !important;
  }
  .footWrapper {
    margin-left: 0px !important;
  }

  .comparisonCellLeft {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 100px;
    width: 120px;
  }

  .comparisonCell {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.05rem;

    border-bottom: 1px solid #ccc;
    height: 80px;
    width: 122px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }
  .smallPrint250,
  .smallPrint1000 {
    margin-bottom: -24px !important;
  }
  .titleCell {
    display: flex;
    width: 174px;
    font-family: Lufga-Regular;
    font-size: 15px;
    border-bottom: 0.5px solid #ccc;
    height: 80px;
    align-items: center;
  }
  .fullWidthBarBlue::after {
    left: 28% !important;
  }
  .fullWidthBarOrange::after {
    left: 68% !important;
  }
  .AffiliateIntro2 {
    height: 450px !important;
  }

  .bikeFall {
    margin-left: -73%;
    width: 90%;
  }

  .Lrqa {
    width: 75%;
    margin-top: -30px;
  }
  .coreGirlImg {
    position: absolute;
    bottom: -50px;
    right: -350px;
    transform: scale(1);
    z-index: -1;
  }
  .coreGirlImgBlue {
    position: absolute;
    bottom: -120px;
    transform: scale(0.8);
    right: -300px;
    z-index: -1;
  }
  .coreManImg {
    position: absolute;
    bottom: -150px;
    left: -50px;
    transform: scale(0.9);
    z-index: -1;
  }
  .coreOverrideTitle {
    width: 300px;
    height: 92px !important;
    font-family: "Lufga-Regular" !important;
    font-size: 22px !important;
  }
  .coreOverrideCol {
    width: 300px;
    height: 92px !important;
    font-family: "Lufga-Medium" !important;
    font-size: 30px !important;
  }

  .coreOverrideCol:nth-child(1) {
    margin-left: 15px;
  }
  .paddingBetweenCols > tbody > tr:first-child .comparisonCell,
  .paddingBetweenCols > tbody > tr:first-child .titleCell {
    height: 102px !important;
  }
  .comparisonCell p {
    margin-top: -3px;
    font-family: "Lufga-Light";
    font-size: 13px;
    color: #bbb;
  }
  .coreSmallprint {
    padding-left: 70px;
    padding-right: 70px;
  }
  .coreIconText {
    padding-right: 20%;
  }
  .coreiconSecondRow {
    padding-right: 60% !important;
  }
  .CorePerformanceIntroFontSize {
    font-size: 29px;
  }
}

/* XL only ============================================================================================================================================*/
@media (min-width: 1200px) and (max-width: 1399.98px) {
  /* homepage */
  .roadBikeTweaks {
    position: absolute;
    right: -160px;
    bottom: -10px;
    transform: scale(1.2);
  }

  .mobileMarginTop {
    margin-top: 40px !important;
  }

  .HeroSingle {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HomePageHero1400w.jpg);
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: bottom;
    min-height: 720px;
  }

  .HeroMulti {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/MultiHero1400.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroElectric {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/EbikeHero1400.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroTravel {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroTravel1400.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroJonny {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroJonny2000.jpg);
    background-repeat: no-repeat;
    min-height: 720px;
    background-size: 100%;
    background-position: bottom;
  }

  .headerBlockCopy {
    color: var(--velo-white);
    position: relative;
    z-index: 999;
    width: 66%;
    float: left;
  }

  .introBlocKBike {
    position: absolute;
    bottom: -150px;
    left: -200px;
    /* z-index: -10; */
  }

  .responsiveAlign {
    text-align: left;
  }

  /* Travel Page */
  .bikeBoxTweaks {
    position: absolute;
    transform: scale(0.6);
    bottom: -200px;
    right: -100px;
  }

  .bikeBox {
    position: absolute;
    left: -348px;
    bottom: -537px;
    transform: scale(0.7);
    z-index: -1;
    z-index: -1;
  }

  .whyTI {
    min-height: 96vh;
  }

  .mustardBike {
    position: absolute;
    right: -791px;
    transform: scale(0.6);
    bottom: -390px;
    z-index: -10;
  }

  /* singleBikeCover page */
  .singleBikeLeisure {
    position: absolute;
    left: -440px;
    bottom: -180px;
    transform: scale(0.75);
    z-index: -10;
  }

  /* multibike cover page */
  .mountImageTweaks {
    position: absolute;
    left: 0px;
    bottom: -450px;
    z-index: 0;
  }

  /* ebike cover page */
  .eBikeTweaks {
    position: absolute;
    right: -196px;
    bottom: -45px;
  }

  .peakImageTweaks {
    position: absolute;
    left: -200px;
    bottom: -40px;
    z-index: 0;
  }

  .typesOfBikeImgLEFTbigger {
    position: absolute;
    right: 20%;
    bottom: -450px;
    transform: scale(0.6);
  }

  .whyChooseveloEbikeImg {
    position: absolute;
    right: -150px;
    bottom: -299px;
    transform: scale(0.7);
  }

  /* policy information page */
  .helmetImage {
    position: absolute;
    bottom: -50px;
    left: -100px;
    transform: scale(0.7);
  }

  .paperImage {
    position: absolute;
    top: -86px;
    left: -60px;
    transform: scale(0.6);
  }

  /* security page */
  .securityMac {
    position: absolute;
    transform: scale(0.45);
    top: -240px;
    right: -137px;
  }

  .securitySoldSecure {
    position: absolute;
    max-height: 400px;
    padding: 0;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }

  .bestLockImage {
    transform: scale(0.6);
  }

  /* faq page */
  .dumbfoundedImage {
    position: absolute;
    right: 150px;
    top: 40px;
    transform: scale(1.1);
  }

  /* cover features */
  .bikerCoupleImage {
    position: absolute;
    right: 4px;
    bottom: -45px;
    transform: scale(1.1);
  }

  .StandardFeaturesBike {
    position: absolute;
    left: -280px;
    bottom: 150px;
    transform: scale(0.6);
  }

  .EbikeFeaturesBike {
    position: absolute;
    right: -760px;
    bottom: -100px;
    transform: scale(0.55);
  }

  /* banner stuff */
  .bannerSVG {
    width: 100% !important;
    transform: translate(516px, -391px) scale(0.6);
  }

  .sharedHeaderBlockCopy p {
    color: white;
    line-height: 1.1em;
    max-width: 400px;
  }

  .sharedHeaderBlockCopy {
    padding-top: 60px;
  }

  #journeyHeadingBlock {
    width: 100%;
    background-color: var(--velo-blue);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    height: 400px;
    padding-top: 45px;
  }

  /* claims */
  .claim-roadbike {
    transform: scale(1.4);
    position: absolute;
    right: -400px;
    bottom: 70px;
  }

  /* about us */
  .bikeCoupleAboutUs {
    position: absolute;
    left: -75px;
    bottom: 20px;
    transform: scale(1.1);
  }

  /* refer a friend */
  .whyRefer {
    min-height: 34vh;
  }

  .policyImage {
    transform: scale(0.6);
    margin-top: -100px;
    margin-bottom: -100px;
  }

  /* types of bikes */
  .typesOfBikeImgLEFT {
    position: absolute;
    left: -199px;
  }

  .typesOfBikeImgRIGHT {
    position: absolute;
    right: -199px;
  }

  /* cover comparison */
  .bikerImage {
    position: absolute;
    left: -200px;
    bottom: -500px;
    transform: scale(0.8);
  }

  /* affiliate page */
  .loyalty {
    position: absolute;
    right: -1150px;
    bottom: -170px;
    transform: scale(0.7);
  }

  /* journey - quote */
  .QuoteValueLarge {
    font-size: 65px !important;
  }

  .or {
    position: absolute;
    top: 23%;
    left: 44.3%;
  }

  .btn-icon-security > svg {
    transform: scale(1.2);
    position: absolute;
    left: 50px;
    top: 10px;
  }

  .btn-icon-security {
    min-height: 55px !important;
  }

  /* how it started */
  .signature {
    left: -115px !important;
    bottom: 156px !important;
    transform: scale(0.45) !important;
  }

  .blueBlock p {
    font-size: 27px;
  }

  .blueBlock {
    height: 500px !important;
  }

  .title {
    margin-top: 100px !important;
  }

  .Ken-name {
    margin-bottom: 20px;
  }

  /* single bike cover */
  .whyChooseSingleCoverImage {
    position: absolute;
    bottom: -490px;
    right: -400px;
    transform: scale(0.6);
  }

  /*  affiliate */
  .toolsImage {
    position: absolute;
    bottom: -147px;
    width: 108%;
  }

  .AffilateScreens {
    transform: scale(0.6);
    margin-top: -203px;
    margin-left: -200px;
  }

  .bannerLaptopImage {
    width: 100%;
    transform: scale(1.6);
    margin-top: 100px;
    margin-left: -100px;
  }

  .KeyBensOrangeBike {
    position: absolute;
    bottom: -250px;
    right: -500px;
    z-index: -10;
  }

  .filterBlogButton {
    min-width: 185px;
    width: 100%;
    margin-bottom: 5px;
  }

  .jonnyTweaks {
    position: absolute;
    left: 750px;
    top: 0px;
  }

  .jonnyIntroBlock {
    position: absolute;
    left: 0px;
    top: 100px;
  }

  .whyJonnyChoseVeloImage {
    position: absolute;
    left: 50%;
    bottom: -150px;
  }

  .EbikeAboutus {
    margin-left: -550px;
    margin-top: 50px;
    transform: scale(1.3);
  }

  .extraPaddingForLargerScreen {
    padding-top: 50px;
  }

  .giftCard {
    padding-top: 40px;
    margin-left: -140px;
    width: 100%;
    bottom: 0;
  }

  /* Refer a friend */
  .bikeCoupleRefer {
    margin-top: 32px;
    width: 49%;
    /* transform: scale(1.4); */
    margin-left: 100px;
  }

  .marginTop {
    margin-top: -200px;
  }

  .spacerForNoCTAScenario {
    height: 23px !important;
  }
  .spacerForNoCTAScenarioExtra {
    height: 50px !important;
  }

  .comparisonCellLeft {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 100px;
    width: 172px;
  }

  .comparisonCell {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.1rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 80px;
    width: 150px;
  }
  .titleCell {
    display: flex;
    width: 184px;
    font-family: Lufga-Regular;
    font-size: 16px;
    border-bottom: 0.5px solid #ccc;
    height: 80px;
    align-items: center;
  }

  .comparisonSmallprint {
    padding: 20px 20px 20px 20px;
  }

  .fullWidthBarBlue::after {
    left: 31.5% !important;
  }
  .fullWidthBarOrange::after {
    left: 65.5% !important;
  }

  .bikeStolenImage {
    transform: scale(1.3);
    margin-left: 90px;
    margin-top: 120px;
  }
  .bikeFall {
    margin-left: -60%;
    width: 160%;
  }
  .Lrqa {
    width: 75%;
    margin-top: -30px;
    margin-top: -30px;
  }
  .wrapper {
    margin-left: 0px !important;
  }
  .coreGirlImg {
    position: absolute;
    bottom: 0;
    right: -350px;
    transform: scale(1.2);
    z-index: -1;
  }
  .coreGirlImgBlue {
    position: absolute;
    bottom: -159px;
    transform: scale(0.8);
    right: -350px;
    z-index: -1;
  }
  .coreManImg {
    position: absolute;
    bottom: -50px;
    left: 160px;
    /* transform: scale(1.2); */
    z-index: -1;
  }
  .coreOverrideTitle {
    width: 400px;
    height: 92px !important;
    font-family: "Lufga-Regular" !important;
    font-size: 22px !important;
  }
  .coreOverrideCol {
    width: 350px;
    height: 92px !important;
    font-family: "Lufga-Medium" !important;
    font-size: 30px !important;
  }

  .coreOverrideCol:nth-child(1) {
    margin-left: 15px;
  }
  .paddingBetweenCols > tbody > tr:first-child .comparisonCell,
  .paddingBetweenCols > tbody > tr:first-child .titleCell {
    height: 102px !important;
  }
  .comparisonCell p {
    margin-top: -3px;
    font-family: "Lufga-Light";
    font-size: 13px;
    color: #bbb;
  }
  .coreSmallprint {
    padding-left: 70px;
    padding-right: 70px;
  }
  .coreIconText {
    padding-right: 20%;
  }
  .CorePerformanceIntroFontSize {
    font-size: 44px;
  }
}

/* XXL only =========================================================================================================================================*/
@media (min-width: 1400px) {
  /* homepage*/
  .mobileMarginTop {
    margin-top: 40px !important;
  }

  .cookie-bar-container {
    padding-left: 100px !important;
    padding-right: 100px !important;
  }

  .HeroSingle {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HomePageHero2000w.jpg);
    background-repeat: no-repeat;
    min-height: 900px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroMulti {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/MultiHero2000.jpg);
    background-repeat: no-repeat;
    min-height: 900px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroElectric {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/EbikeHero2000.jpg);
    background-repeat: no-repeat;
    min-height: 900px;
    background-size: 100%;
    background-position: bottom;
  }

  .HeroTravel {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroTravel2000.jpg);
    background-repeat: no-repeat;
    min-height: 900px;
    background-position: bottom;
  }

  .HeroJonny {
    width: 100%;
    background-color: var(--velo-blue);
    background-image: url(../Images/HeroJonny2000.jpg);
    background-repeat: no-repeat;
    min-height: 900px;
    background-size: 85%;
    background-position: bottom right;
  }

  .headerBlockCopy {
    color: var(--velo-white);
    position: relative;
    z-index: 999;
    width: 66%;
    float: left;
  }

  .introBlocKBike {
    position: absolute;
    bottom: -150px;
    left: -100px;
    /* z-index: -1; */
  }

  .responsiveAlign {
    text-align: left;
  }

  /* Travel Page */
  .bikeBoxTweaks {
    position: absolute;
    transform: scale(0.6);
    bottom: -200px;
    right: -100px;
  }

  .mustardBike {
    position: absolute;
    right: -759px;
    transform: scale(0.6);
    bottom: -420px;
    z-index: -10;
  }

  .bikeBox {
    position: absolute;
    left: -286px;
    bottom: -570px;
    transform: scale(0.6);
    z-index: -1;
  }

  .whyTI {
    min-height: 85vh;
  }

  /* singleBikeCover page */
  .singleBikeLeisure {
    position: absolute;
    left: -447px;
    bottom: -180px;
    transform: scale(0.9);
    z-index: -10;
  }

  /* multibike cover page */
  .mountImageTweaks {
    position: absolute;
    left: 0px;
    bottom: -450px;
    z-index: 0;
  }

  /* ebike cover page */
  .eBikeTweaks {
    position: absolute;
    right: -46px;
    bottom: -50px;
    transform: scale(1);
  }

  .peakImageTweaks {
    position: absolute;
    left: 300px;
    bottom: -40px;
    z-index: 0;
  }

  .typesOfBikeImgLEFTbigger {
    position: absolute;
    right: 30%;
    bottom: -450px;
    transform: scale(0.6);
  }

  .whyChooseveloEbikeImg {
    position: absolute;
    right: -12px;
    bottom: -335px;
    transform: scale(0.7);
  }

  /* policy information page */
  .helmetImage {
    position: absolute;
    bottom: -33px;
    transform: scale(0.8);
  }

  .paperImage {
    position: absolute;
    top: -86px;
    left: -60px;
    transform: scale(0.6);
  }

  /* security page */
  .securityMac {
    position: absolute;
    transform: scale(0.45);
    top: -244px;
    right: -46px;
  }

  .securitySoldSecure {
    position: absolute;
    max-height: 400px;
    padding: 0;
    top: -75px;
    left: 50%;
    transform: translateX(-50%);
    transition: all 0.5s ease-in-out;
  }

  .bestLockImage {
    transform: scale(0.6);
  }

  /* faq page */
  .dumbfoundedImage {
    position: absolute;
    left: 1000px;
    top: 50px;
    transform: scale(1.1);
  }

  /* cover features */
  .bikerCoupleImage {
    position: absolute;
    right: 10px;
    bottom: -45px;
    transform: scale(1.1);
  }

  .StandardFeaturesBike {
    position: absolute;
    left: -273px;
    bottom: 150px;
    transform: scale(0.8);
  }

  .EbikeFeaturesBike {
    position: absolute;
    right: -700px;
    bottom: -100px;
    transform: scale(0.7);
  }

  /* banner stuff */
  .bannerSVG {
    width: 100% !important;
    transform: translate(653px, -391px) scale(0.62);
  }

  .sharedHeaderBlockCopy p {
    color: white;
    line-height: 1.1em;
    max-width: 400px;
  }

  .sharedHeaderBlockCopy {
    padding-top: 60px;
  }

  #journeyHeadingBlock {
    width: 100%;
    background-color: var(--velo-blue);
    margin-left: 0;
    margin-right: 0;
    margin-top: 3rem;
    height: 400px;
    padding-top: 45px;
  }

  /* claims */
  .claim-roadbike {
    transform: scale(1.4);
    position: absolute;
    right: -400px;
    bottom: 70px;
  }

  /* about us */
  .bikeCoupleAboutUs {
    position: absolute;
    left: 112px;
    bottom: 34px;
    transform: scale(1.15);
  }

  /* refer a friend */
  .whyRefer {
    height: 40vh;
  }

  .policyImage {
    transform: scale(0.6);
    margin-top: -100px;
    margin-bottom: -100px;
  }

  /* types of bikes */
  .typesOfBikeImgLEFT {
    position: absolute;
    left: -99px;
  }

  .typesOfBikeImgRIGHT {
    position: absolute;
    right: -99px;
  }

  /* cover comparison */
  .bikerImage {
    position: absolute;
    left: -200px;
    bottom: -550px;
    transform: scale(0.8);
  }

  /* journey - quote */
  .QuoteValueLarge {
    font-size: 60px !important;
  }

  .or {
    position: absolute;
    top: 23%;
    left: 44.3%;
  }

  .btn-icon-security > svg {
    transform: scale(1.2);
    position: absolute;
    left: 53px;
    top: 10px;
  }

  .btn-icon-security {
    min-height: 55px !important;
  }

  /* affiliate page */
  .loyalty {
    position: absolute;
    right: -1200px;
    bottom: -190px;
    transform: scale(0.7);
  }

  .AffilateScreens {
    transform: scale(0.7);
    margin-top: -81px;
    margin-bottom: -100px;
    margin-left: -200px;
  }

  .toolsImage {
    position: absolute;
    bottom: -148px;
    left: 20px;
    width: 99%;
  }

  /* single bike cover */
  .whyChooseSingleCoverImage {
    position: absolute;
    bottom: -560px;
    right: -300px;
    transform: scale(0.7);
  }

  .bannerLaptopImage {
    width: 100%;
    transform: scale(1.6);
    margin-top: 100px;
    margin-left: -100px;
  }

  .KeyBensOrangeBike {
    position: absolute;
    bottom: -350px;
    right: -700px;
    z-index: -10;
  }

  .filterBlogButton {
    min-width: 185px;
    width: 100%;
    margin-bottom: 5px;
  }

  .jonnyIntroBlock {
    position: absolute;
    left: 0px;
    top: 100px;
  }

  .whyJonnyChoseVeloImage {
    position: absolute;
    left: 55%;
    bottom: -150px;
  }

  .EbikeAboutus {
    margin-left: -550px;
    margin-top: 50px;
    transform: scale(1.3);
  }

  .extraPaddingForLargerScreen {
    padding-top: 20px;
  }
  .giftCard {
    padding-top: 50px;
    margin-left: -150px;
    width: 100%;
  }

  /* Refer a friend */
  .bikeCoupleRefer {
    margin-top: 32px;
    width: 49%;
    /* transform: scale(1.4); */
    margin-left: 100px;
  }

  .marginTop {
    margin-top: -200px;
  }

  .spacerForNoCTAScenario {
    height: 23px !important;
  }
  .spacerForNoCTAScenarioExtra {
    height: 50px !important;
  }

  /* how it started */
  .signature {
    left: -115px !important;
    bottom: 56px !important;
    transform: scale(0.45) !important;
  }

  .comparisonCellLeft {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    padding-top: 28px;
    padding-bottom: 28px;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 90px;
    width: 172px;
  }

  .comparisonCell {
    background-color: #fff;
    color: #7a7a7a;
    padding: 20px;
    /* padding-top: 28px; */
    padding-bottom: 28px;
    font-size: 1.2rem;
    text-align: center;
    border-bottom: 1px solid #ccc;
    height: 73px;
    width: 174px;
  }
  .titleCell {
    display: flex;
    width: 225px;
    font-family: "Lufga-Regular";
    font-size: 16px;
    border-bottom: 0.5px solid #ccc;
    height: 73px;
    align-items: center;
  }

  .comparisonSmallprint {
    padding: 20px 20px 20px 20px;
  }

  .bikeStolenImage {
    transform: scale(1.3);
    margin-left: 90px;
    margin-top: 100px;
  }
  .bikeFall {
    /* margin-left: -30%;
    width: 130%; */
  }
  .Lrqa {
    width: 75%;
    margin-top: -30px;
  }

  .coreGirlImg {
    position: absolute;
    bottom: 0;
    right: -350px;
    transform: scale(1.2);
    z-index: -1;
  }
  .coreGirlImgBlue {
    position: absolute;
    bottom: -159px;
    transform: scale(0.8);
    right: -350px;
    z-index: -1;
  }
  .coreManImg {
    position: absolute;
    bottom: -50px;
    left: 300px;
    /* transform: scale(1.2); */
    z-index: -1;
  }
  .coreOverrideTitle {
    width: 400px;
    height: 92px !important;
    font-family: "Lufga-Regular" !important;
    font-size: 22px !important;
  }
  .coreOverrideCol {
    width: 400px;
    height: 92px !important;
    font-family: "Lufga-Medium" !important;
    font-size: 30px !important;
  }

  .coreOverrideCol:nth-child(1) {
    margin-left: 15px;
  }
  .paddingBetweenCols > tbody > tr:first-child .comparisonCell,
  .paddingBetweenCols > tbody > tr:first-child .titleCell {
    height: 102px !important;
  }
  .comparisonCell p {
    margin-top: -3px;
    font-family: "Lufga-Light";
    font-size: 13px;
    color: #bbb;
  }
  .coreSmallprint {
    padding-left: 70px;
    padding-right: 70px;
  }
  .coreIconText {
    padding-right: 10%;
  }

  .coreiconSecondRow {
    padding-right: 10% !important;
  }
  .negMarginLeft {
    margin-left: -93px;
  }
  .negMargin {
    margin-left: -30px;
  }
  .negMarginMore {
    margin-left: -60px;
  }

  .CorePerformanceIntroFontSize {
    font-size: 44px;
  }
}
