/*  Bootstrap container Overrides  

    * note that we are not altering container fluid
    creating 3 content widths 1400, 1259 and 1109 ( these are at width 1920 and will need media query equivs)
*/

.container_mega_wide {
  max-width: 2000px !important;
}
.container_extra_wide {
  max-width: 1600px !important;
}

.container_wide {
  max-width: 1400px !important;
}

.container_medium {
  max-width: 1259px !important;
}

.container_narrow {
  max-width: 1109px !important;
}

.container-fluid {
  margin: 0 !important;
  padding: 0 !important;
}
